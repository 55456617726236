/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';
import DefaultInfoCardTwo from '../../../../../examples/Cards/InfoCards/DefaultInfoCardTwo';

//Images
import health_industry_4 from '../../../../../assets/images/industry/health_industry_4.jpeg';
import health_industry_3 from '../../../../../assets/images/industry/health_industry_3.jpeg';
import portfolio_details_4 from '../../../../../assets/images/software-development/portfolio_details_4.png';
import portfolio_details_3 from '../../../../../assets/images/software-development/portfolio_details_3.png';
import portfolio_details_1 from '../../../../../assets/images/software-development/portfolio_details_1.png';
import portfolio_details_5 from '../../../../../assets/images/software-development/portfolio_details_2.png';

// Css
import './HealthMonitoringCaseStudyDetails.css';

function HealthMonitoringCaseStudyDetails() {
  return (
    <>
      <MKBox component="section" pt={12} pb={5}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={5} sx={{ ml: '', mt: { xs: 3, lg: 0 } }}>
              <img src={health_industry_3} className="image-portfolio-details" />
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={12}>
                  <MKBox>
                    <DefaultInfoCardTwo
                      title="Health Monitoring"
                      description="In this use case, we delve into an innovative application that empowers individuals to take charge of their health and fitness journey. This application serves as a comprehensive tool for tracking and monitoring various aspects of personal well-being, such as physical activity, nutrition, sleep patterns, and overall fitness goals. By providing users with real-time data, personalized insights, and progress tracking features, the application aims to motivate and support individuals in achieving their desired health and fitness outcomes."
                    />
                    <div className="container">
                      <div className="box-portfolio-details">
                        <img src={portfolio_details_4} alt="Country" />
                        <p>
                          <b>Country:</b> Jamaica
                        </p>
                      </div>
                      <div className="box-portfolio-details">
                        <img src={portfolio_details_3} alt="Platform" />
                        <p>
                          <b>Platform:</b> iOS , Web
                        </p>
                      </div>
                      <div className="box-portfolio-details">
                        <img src={portfolio_details_1} alt="Industry" />
                        <p>
                          <b>Industry:</b> Health Care
                        </p>
                      </div>
                      <div className="box-portfolio-details">
                        <img src={portfolio_details_5} alt="Duration" />
                        <p>
                          <b>Duration:</b> 2021-Present
                        </p>
                      </div>
                    </div>
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" pb={5} style={{ background: '#f0f2f5' }}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} sx={{ ml: '', mt: { xs: 3, lg: 10 }, mb: { xs: 3, lg: 5 } }}>
              <DefaultInfoCardTwo title="Lorem" description="Lorem ipsom" />
            </Grid>
          </Grid>

          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Team</h2>*/}
                {/*<p className="card-detail">Backend Developer</p>*/}
                {/*<p className="card-detail">Database Administrator</p>*/}
                {/*<p className="card-detail">Frontend Developer</p>*/}
                {/*<p className="card-detail">Quality Assurance Engineer</p>*/}
                {/*<p className="card-detail">Project Manager</p>*/}
                {/*<p className="card-detail">UI/UX Designer</p>*/}
              </div>
            </Grid>
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Tech Stack:</h2>*/}
                {/*<p className="card-detail">Angular</p>*/}
                {/*<p className="card-detail">Python (Django)</p>*/}
                {/*<p className="card-detail"> PostgreSQL</p>*/}
              </div>
            </Grid>
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Target Audience</h2>*/}
                {/*<p className="card-detail">Proactive Indiividuals</p>*/}
                {/*<p className="card-detail">Health-conscious Individuals</p>*/}
              </div>
            </Grid>
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Services</h2>*/}
                {/*<p className="card-detail">Health Data Tracking</p>*/}
                {/*<p className="card-detail">Personalized Insights and Recommendations</p>*/}
                {/*<p className="card-detail">Activity Monitoring and Goal Setting</p>*/}
                {/*<p className="card-detail">Health History and Trends Analysis:</p>*/}
                {/*<p className="card-detail">Integration with Healthcare Providers </p>*/}
              </div>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" pb={5}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={5} sx={{ ml: '', mt: { xs: 10, lg: 10 } }}>
              <img src={health_industry_4} className="image-portfolio-details" />
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={12}>
                  <MKBox>
                    <DefaultInfoCardTwo
                      title="Opportunities"
                      description="A health and wellness startup, aimed to develop an application that would empower individuals to track and monitor their health and fitness goals. They sought a comprehensive solution that would provide users with personalized insights, real-time data tracking, and actionable recommendations. The application needed to be user-friendly, scalable, and capable of integrating with various health tracking devices."
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" pb={5} style={{ background: '#f0f2f5' }}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} sx={{ ml: '', mt: { xs: 3, lg: 10 } }}>
              <DefaultInfoCardTwo
                title="Our Solution"
                description="By partnering with us, together we developed  to develop a robust health monitoring application, HealthTrack. Our team conducted extensive research and collaborated closely with the client to understand their vision and requirements. "
              />
            </Grid>
          </Grid>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} md={12} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <h1 className="portfolio-details-text-header">
                The key outcomes of the project included
              </h1>
              <ul className="portfolio-details-text">
                <li className="card-detail">
                  <span>
                    <b>Empowered Users:: </b>
                  </span>
                  The application empowered users to take control of their health by providing
                  real-time data tracking, personalized insights, and actionable recommendations.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Improved Health Awareness: </b>
                  </span>
                  Users gained a deeper understanding of their health status, patterns, and
                  progress, enabling them to make informed decisions about their lifestyle and
                  wellness goals.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Enhanced Motivation and Accountability: </b>
                  </span>
                  The application goal-setting features and community engagement fostered motivation
                  and accountability, helping users stay committed to their health and fitness
                  journey.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Seamless Integration with Health Tracking Devices: </b>
                  </span>
                  Seamless Integration with Health Tracking Devices The application seamlessly
                  integrated with popular wearable devices and health tracking apps, allowing users
                  to effortlessly sync their health data for a comprehensive view of their
                  well-being.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Enhanced Collaboration with Healthcare Providers: </b>
                  </span>
                  The application facilitated improved collaboration between users and their
                  healthcare providers, enabling better communication and informed decision-making.
                </li>
              </ul>
            </Grid>
          </Grid>

          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} sx={{ ml: '', mt: { xs: 3, lg: 3 } }}>
              <DefaultInfoCardTwo
                title="Conclusion"
                description="The collaboration  resulted in the successful development of a comprehensive health monitoring
               application. By leveraging a customized tech stack and incorporating user-centric features, The application
               empowered individuals to monitor their health, gain personalized insights, and make informed decisions about
                their well-being. The seamless integration with health tracking devices and healthcare providers enhanced
                user experience and facilitated better collaboration in the healthcare ecosystem. The application positioned
                 itself as a reliable companion in individuals' health journeys, offering a robust platform for health tracking,
                  personalized recommendations, and community engagement."
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default HealthMonitoringCaseStudyDetails;
