/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKTypography from 'components/typography';
import MKBox from 'components/box';

// Service Band Section
import EnterpriseDataManagementBand from '../sections/EnterpriseDataManagementBand';
import DataAnalyticsBand from '../sections/DataAnalyticsBand';
import ManagedServicesAndStaffAugmentationBand from '../sections/ManagedServicesAndStaffAugmentationBand';
import TrainingBand from '../sections/TrainingBand';
import SoftwareDevelopmentBand from '../sections/SoftwareDevelopmentBand';
import IDMAPSBand from '../sections/IDMAPSBand';

function ServiceBand() {
  return (
    <>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ mx: 'auto', textAlign: 'center', pt: '100px' }}
        >
          <MKTypography
            variant="h1"
            color="#344667"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down('md')]: {
                fontSize: size['3xl'],
              },
            })}
          >
            Services
          </MKTypography>
          <MKTypography variant="body1" color="#344667" opacity={0.8} mt={1} mb={1}>
            We are thrilled to present our comprehensive range of services designed to meet your
            specific needs and drive your business forward.
          </MKTypography>
        </Grid>
      </Container>
      <MKBox component="section" pt={5}>
        <Container>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} lg={4}>
              <DataAnalyticsBand disType="band" />
            </Grid>
            <Grid item xs={12} lg={4}>
              <EnterpriseDataManagementBand disType="band" />
            </Grid>
            <Grid item xs={12} lg={4}>
              <SoftwareDevelopmentBand disType="band" />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TrainingBand disType="band" />
            </Grid>
            <Grid item xs={12} lg={4}>
              <ManagedServicesAndStaffAugmentationBand disType="band" />
            </Grid>
            <Grid item xs={12} lg={4}>
              <IDMAPSBand disType="band" />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default ServiceBand;
