/**
 =========================================================
 * Material Kit 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router components
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// @mui material components
import { Card, Grid, Icon, Menu, MenuItem } from '@mui/material';
import MuiLink from '@mui/material/Link';

// Material Kit 2 React components
import MKBox from 'components/box';
import MKTypography from 'components/typography';
import MKButton from 'components/button';
import { useState } from 'react';

function CenteredBlogCard({ image, title, description, action, menuItems }) {
  const [dropdown, setDropdown] = useState(null);
  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = () => setDropdown(null);

  // Styles
  const iconStyles = {
    ml: 1,
    fontWeight: 'bold',
    transition: 'transform 200ms ease-in-out',
  };

  const dropdownIconStyles = {
    transform: dropdown ? 'rotate(180deg)' : 'rotate(0)',
    ...iconStyles,
  };

  return (
    <Card>
      <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
        <MKBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          width="100%"
          position="relative"
          zIndex={1}
        />
        <MKBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${image})`,
            transform: 'scale(0.94)',
            filter: 'blur(12px)',
            backgroundSize: 'cover',
          }}
        />
      </MKBox>
      <MKBox p={3} mt={-1} textAlign="center">
        <MKTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="regular">
          {title}
        </MKTypography>
        <MKBox mt={1} mb={3}>
          <MKTypography variant="body2" component="p" color="text">
            {description}
          </MKTypography>
        </MKBox>
        {action.type === 'external' ? (
          <MKButton
            component={MuiLink}
            href={action.route}
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            size="small"
            color={action.color ? action.color : 'dark'}
          >
            {action.label}
          </MKButton>
        ) : action.type === 'dropdown' ? (
          <Grid item xs={12} lg={12}>
            <MKButton variant="gradient" color="info" size="small" onClick={openDropdown}>
              {action.label} <Icon sx={dropdownIconStyles}>expand_more</Icon>
            </MKButton>
            <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}>
              {Object.keys(menuItems.route).map((key) => (
                <MenuItem key={key} component={MuiLink} href={menuItems.route[key]}>
                  {menuItems.menuItemLabels[key]}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        ) : (
          <MKButton
            component={Link}
            to={action.route}
            variant="gradient"
            size="small"
            color={action.color ? action.color : 'dark'}
          >
            {action.label}
          </MKButton>
        )}
      </MKBox>
    </Card>
  );
}

// Typechecking props for the CenteredBlogCard
CenteredBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      education: PropTypes.shape({
        route: PropTypes.string.isRequired,
        menuItemLabels: PropTypes.string.isRequired,
      }).isRequired,
      ecommerce: PropTypes.shape({
        route: PropTypes.string.isRequired,
        buttonLabel: PropTypes.string.isRequired,
        menuItemLabels: PropTypes.string.isRequired,
      }).isRequired,
      health: PropTypes.shape({
        route: PropTypes.string.isRequired,
        buttonLabel: PropTypes.string.isRequired,
        menuItemLabels: PropTypes.string.isRequired,
      }).isRequired,
      finance: PropTypes.shape({
        route: PropTypes.string.isRequired,
        buttonLabel: PropTypes.string.isRequired,
        menuItemLabels: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(['external', 'dropdown', 'internal']).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'light',
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default CenteredBlogCard;
