/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from '../../../components/box';
import MKInput from '../../../components/input';
import MKButton from '../../../components/button';
import MKTypography from '../../../components/typography';

// Images
// import contact_us_1 from '../../../assets/images/contact-us-page/contact_us_1.jpeg';
import bgImage from '../../../assets/images/examples/blog2.jpg';
import { Link } from 'react-router-dom';

function Contact() {
  return (
    <MKBox
      component="section"
      py={{ xs: 0, lg: 6 }}
      // style={{
      //   backgroundImage: `url(${contact_us_1})`,
      //   backgroundSize: 'cover',
      //   backgroundRepeat: 'no-repeat',
      //   backgroundPosition: 'center',
      // }}
    >
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            mt={10}
            sx={{ overflow: 'hidden' }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8),
                    )}, url(${bgImage})`,
                  backgroundSize: 'cover',
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Contact Information
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Fill up the form and our Team will get back to you within 24 hours.
                    </MKTypography>
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        (876)-381-5911
                      </MKTypography>
                    </MKBox>

                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        Unit B 6 Haining Road Kingston 5
                      </MKTypography>
                    </MKBox>

                    <MKBox mt={3}>
                      {
                        <MKButton
                          variant="text"
                          color="white"
                          size="large"
                          iconOnly
                          target="_blank"
                          component={Link}
                          to="https://www.facebook.com/pages/category/Information-Technology-Company/Idmaps-102405328308455/"
                        >
                          <i className="fab fa-facebook" style={{ fontSize: '1.25rem' }} />
                        </MKButton>
                      }
                      {
                        <MKButton
                          variant="text"
                          color="white"
                          size="large"
                          iconOnly
                          target="_blank"
                          component={Link}
                          to="https://www.linkedin.com/company/idmaps-intl/?viewAsMember=true"
                        >
                          <i className="fab fa-linkedin" style={{ fontSize: '1.25rem' }} />
                        </MKButton>
                      }
                      {
                        <MKButton
                          variant="text"
                          color="white"
                          size="large"
                          iconOnly
                          target="_blank"
                          component={Link}
                          to="https://www.youtube.com/@IDMAPSInternational"
                        >
                          <i className="fab fa-youtube" style={{ fontSize: '1.25rem' }} />
                        </MKButton>
                      }
                      {
                        <MKButton
                          variant="text"
                          color="white"
                          size="large"
                          iconOnly
                          target="_blank"
                          component={Link}
                          to="https://www.instagram.com/id_maps/?hl=en"
                        >
                          <i className="fab fa-instagram" style={{ fontSize: '1.25rem' }} />
                        </MKButton>
                      }
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      Say Hi!
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      We&apos;d like to talk with you.
                    </MKTypography>
                  </MKBox>
                  <MKBox width="100%" component="form" method="post" autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          variant="standard"
                          label="Firstname"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          variant="standard"
                          label="Lastname"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          variant="standard"
                          label="Company/Business"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          variant="standard"
                          label="Job Title"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <MKInput
                          type="email"
                          variant="standard"
                          label="Email"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          variant="standard"
                          label="Phone Number"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          variant="standard"
                          label="What can we help you?"
                          placeholder="Describe your problem in at least 250 characters"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          fullWidth
                          rows={6}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      mt={5}
                      mb={2}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton variant="gradient" color="info">
                        Send Message
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
