/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from '../../../components/box';

// Material Kit 2 React examples
import CenteredBlogCard2 from '../../../examples/Cards/BlogCards/CenteredBlogCard2';
// import ForgotPasswordModal from './ForgotPasswordModal';

function Cards() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} justifyContent="center">
          {/*<Grid item xs={12} lg={4} sx={{ ml: '', mt: { xs: 3, lg: 0 } }}>*/}
          {/*  <CenteredBlogCard2*/}
          {/*    icon="dashboard"*/}
          {/*    title="Forgot your password?"*/}
          {/*    description="let us help you to get back into your account."*/}
          {/*    action={{*/}
          {/*      type: 'internal',*/}
          {/*      route: '/pages/forgot-password',*/}
          {/*      color: 'info',*/}
          {/*      label: 'Get help',*/}
          {/*    }}*/}
          {/*  />*/}
          {/*<ForgotPasswordModal />*/}
          {/*</Grid>*/}
          <Grid item xs={12} lg={4}>
            <Grid container>
              <Grid item xs={12} lg={12} md={12} sm={12}>
                <CenteredBlogCard2
                  icon="dashboard"
                  title="Contact Us"
                  description="We would love to hear from you."
                  action={{
                    type: 'internal',
                    route: '/pages/contact-us',
                    color: 'info',
                    label: 'Chat with us',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container>
              <Grid item xs={12} lg={12} md={12} sm={12}>
                <CenteredBlogCard2
                  icon="dashboard"
                  title="Service"
                  description="View all the services we offer."
                  action={{
                    type: 'internal',
                    route: '/pages/landing-pages/services',
                    color: 'info',
                    label: 'View now',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Cards;
