/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React components
import MKBox from '../../components/box';

// Material Kit 2 React examples
import DefaultNavbar from '../../examples/Navbars/DefaultNavbar';
import DefaultFooter from '../../examples/Footers/DefaultFooter';

// Routes
import routes from '../../routes';
import footerRoutes from '../../footer.routes';

//  Sections
import Cards from './sections/Cards';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MKTypography from '../../components/typography';
import Card from '@mui/material/Card';

// Images
import support_header_image from '../../assets/images/presentation/data_science_globe1.gif';
// import support_header_image from '../../assets/images/industry/portfolio_gif.gif';

function Support() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: 'internal',
          route: '/pages/authentication/sign-in',
          label: 'Sign In',
          color: 'default',
        }}
        transparent
        light
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${support_header_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: 'auto', textAlign: 'center' }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down('md')]: {
                  fontSize: size['3xl'],
                },
              })}
            >
              Support
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              We are dedicated to providing prompt assistance and resolving any queries or issues
              you may have, ensuring a seamless and satisfying customer experience.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ mx: 'auto', textAlign: 'center' }}
        ></Grid>
        <Cards />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Support;
