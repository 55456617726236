/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// CSS
import './Training.css';
import training_6 from '../../../assets/images/training/training_6.jpeg';
import training_7 from '../../../assets/images/training/training_7.jpeg';
import training_8 from '../../../assets/images/training/training_8.jpeg';
import training_9 from '../../../assets/images/training/training_9.jpeg';

function Certification() {
  return (
    <MKBox component="section" pt={5}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox pt={5}>
                  <div className="training-benefit">
                    <span>Certification for Expertise and Credibility</span>
                  </div>
                  <div className="training-description">
                    <p>
                      In addition to training, we offer certification programs that validate the
                      expertise and skills acquired by your staff. Our certifications are recognized
                      and respected in the industry, providing a formal recognition of their
                      capabilities. Certification not only boosts the confidence and credibility of
                      your staff but also enhances their professional growth and career prospects.
                      It demonstrates their commitment to excellence and positions them as subject
                      matter experts in their respective fields.
                    </p>
                    <p className="training-benefit">
                      By choosing our training and certification programs, you benefit from:
                    </p>
                  </div>
                  <div className="training-card-container">
                    <div className="training-card">
                      <div className="training-card-content">
                        <img src={training_6} alt="Image 1" className="training-card-image" />
                        <div className="training-card-details">
                          <h3 className="training-card-title">Validation of Skills</h3>
                          <p className="training-card-description">
                            Certification provides an independent verification of your staff&apos;s
                            skills and knowledge in a specific area. This gives you confidence that
                            they possess the necessary expertise to perform their roles effectively.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="training-card">
                      <div className="training-card-content">
                        <img src={training_7} alt="Image 2" className="training-card-image" />
                        <div className="training-card-details">
                          <h3 className="training-card-title">Industry Recognition</h3>
                          <p className="training-card-description">
                            Our certifications are highly regarded in the industry, enhancing the
                            reputation of your staff and your organization. It showcases your
                            commitment to excellence and positions you as a leader in your field.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="training-card">
                      <div className="training-card-content">
                        <img src={training_8} alt="Image 3" className="training-card-image" />
                        <div className="training-card-details">
                          <h3 className="training-card-title">Competitive Advantage</h3>
                          <p className="training-card-description">
                            Having certified professionals sets your organization apart from
                            competitors. It demonstrates your dedication to quality and competence,
                            giving you a competitive edge when pursuing new opportunities or
                            engaging with clients.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="training-card">
                      <div className="training-card-content">
                        <img src={training_9} alt="Image 4" className="training-card-image" />
                        <div className="training-card-details">
                          <h3 className="training-card-title">Continuous Learning and Growth</h3>
                          <p className="training-card-description">
                            Our certification programs encourage ongoing professional development.
                            By staying up to date with the latest industry trends and best
                            practices, your staff can continue to expand their knowledge and enhance
                            their skills, ensuring they remain at the forefront of their field.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Certification;
