// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

// Material Kit 2 React components
import MKTypography from 'components/typography';

// Images
import logoCT from 'assets/images/logos/IDMAPS-Logo-3D.png';

const date = new Date().getFullYear();

export default {
  brand: {
    name: 'Innovative Data Mining and Advanced Analytics Partners',
    image: logoCT,
    route: '/',
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: 'https://www.facebook.com/pages/category/Information-Technology-Company/Idmaps-102405328308455/',
    },
    {
      icon: <InstagramIcon />,
      link: 'https://www.instagram.com/id_maps/?hl=en',
    },
    {
      icon: <YouTubeIcon />,
      link: 'https://www.youtube.com/@IDMAPSInternational',
    },
    {
      icon: <LinkedInIcon />,
      link: 'https://www.linkedin.com/company/idmaps-intl/?viewAsMember=true',
    },
  ],
  menus: [
    {
      name: '',
      items: [
        // { name: 'illustrations', href: 'https://iradesign.io/' },
        // { name: 'bits & snippets', href: 'https://www.creative-tim.com/bits' },
        // { name: 'affiliate program', href: 'https://www.creative-tim.com/affiliates/new' },
      ],
    },
    {
      name: 'company',
      items: [{ name: 'about us', href: '/pages/landing-pages/about-us' }],
    },
    {
      name: 'help & support',
      items: [{ name: 'contact us', href: '/pages/contact-us' }],
    },
    {
      name: 'legal',
      items: [
        { name: 'terms of service', href: '/pages/terms_of_service' },
        { name: 'privacy policy', href: '/pages/privacy_policy' },
        { name: 'licenses (EULA)', href: '' },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Innovative Data Mining and Advanced Analytics
      Partners (IDMAPS)
    </MKTypography>
  ),
};
