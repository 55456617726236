/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// Material Kit 2 React examples
import DefaultInfoCard from 'examples/Cards/InfoCards/DefaultInfoCard';
import CenteredBlogCard from 'examples/Cards/BlogCards/CenteredBlogCard';

//Image
import services_jamaica_5 from '../../../../assets/images/services-page/services_jamaica_5.jpeg';

// eslint-disable-next-line react/prop-types
export default function SoftwareDevelopmentBand({ disType }) {
  const dataPage = (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6} sx={{ mr: 'auto', mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={services_jamaica_5}
              title="Software Development Service"
              description="With our expertise in the latest technologies and frameworks, we deliver outstanding
                    solutions tailored to your needs. From captivating web applications to intuitive mobile apps, we
                    combine innovation and technical prowess to bring your ideas to life."
              action={{
                type: 'internal',
                route: '/pages/service/software-development-more',
                color: 'info',
                label: 'find out more',
              }}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={1}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Software"
                    description="With our expertise in the latest technologies and frameworks, we deliver outstanding
                    solutions tailored to your needs. From captivating web applications to intuitive mobile apps, we
                    combine innovation and technical prowess to bring your ideas to life."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );

  const servicePageInfo = (
    <MKBox component="section" pt={6}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <CenteredBlogCard
              image={services_jamaica_5}
              title="Software Development"
              description=""
              // With our expertise in the latest technologies and frameworks, we deliver outstanding
              // solutions tailored to your needs. From captivating web applications to intuitive mobile apps, we combine
              // innovation and technical prowess to bring your ideas to life."
              action={{
                type: 'internal',
                route: '/pages/service/software-development-more',
                color: 'info',
                label: 'find out more',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );

  return <div>{disType !== '' && disType === 'band' ? servicePageInfo : dataPage}</div>;
}
