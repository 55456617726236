/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Kit 2 React components
import MKBox from 'components/box';
import MKTypography from 'components/typography';

// Material Kit 2 React examples
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import DefaultFooter from 'examples/Footers/DefaultFooter';

// Managed Services And Staff Augmentation sections
import WhyUs from './sections/WhyUs';
import ManagedServicesAndStaffAugmentation from './sections/ManagedServicesAndStaffAugmentation';
import Benefits from './sections/Benefits';
import Contact from '../ContactUs/sections/Contact';

// Routes
import routes from 'routes';
import footerRoutes from 'footer.routes';

// Images
import managed_services_and_staff_augmentation_header_image from 'assets/images/managed-staff-and-augmentation/managed_services_and_staff_augmentation_gif.gif';

function ManagedServicesAndStaffAugmentationInfo() {
  return (
    <>
      <DefaultNavbar
        routes={routes.map((r) => r.type === 'nav' && r)}
        action={{
          type: 'internal',
          route: '/pages/authentication/sign-in',
          label: 'Sign In',
          color: 'default',
        }}
        transparent
        light
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6),
            )}, url(${managed_services_and_staff_augmentation_header_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: 'auto', textAlign: 'center' }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down('md')]: {
                  fontSize: size['3xl'],
                },
              })}
            >
              Managed Services and Staff Augmentation
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Experienced professional specializing in managed services and staff augmentation.
              Dedicated to providing reliable solutions and augmenting teams with top talent to
              drive organizational success.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <ManagedServicesAndStaffAugmentation />
        <WhyUs />
        <Benefits />
        <Contact />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ManagedServicesAndStaffAugmentationInfo;
