/**
 =========================================================
 * Material Kit 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

// @mui material components
import Card from '@mui/material/Card';
import MuiLink from '@mui/material/Link';

// Material Kit 2 React components
import MKBox from 'components/box';
import MKTypography from 'components/typography';
import MKButton from 'components/button';

function CenteredBlogCard2({ icon, title, description, action, direction, color }) {
  return (
    <Card>
      <MKBox p={3} mt={-1} textAlign="center">
        <MKBox mt={1} mb={3}>
          {typeof icon === 'string' ? (
            <MKTypography
              display="block"
              variant={direction === 'center' ? 'h2' : 'h3'}
              color={color}
              textGradient
            >
              <Icon>{icon}</Icon>
            </MKTypography>
          ) : (
            icon
          )}
        </MKBox>
        <MKTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="regular">
          {title}
        </MKTypography>
        <MKBox mt={1} mb={3}>
          <MKTypography variant="body2" component="p" color="text">
            {description}
          </MKTypography>
        </MKBox>
        {action.type === 'external' ? (
          <MKButton
            component={MuiLink}
            href={action.route}
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            size="small"
            color={action.color ? action.color : 'dark'}
          >
            {action.label}
          </MKButton>
        ) : (
          <MKButton
            component={Link}
            to={action.route}
            variant="gradient"
            size="small"
            color={action.color ? action.color : 'dark'}
          >
            {action.label}
          </MKButton>
        )}
      </MKBox>
    </Card>
  );
}

// Setting default props for the DefaultInfoCard
CenteredBlogCard2.defaultProps = {
  color: 'info',
  direction: 'left',
  small: false,
};

// Typechecking props for the CenteredBlogCard
CenteredBlogCard2.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  icon: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(['left', 'right', 'center']),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(['external', 'internal']).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'light',
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default CenteredBlogCard2;
