/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// @mui material components
import Container from '@mui/material/Container';

import Grid from '@mui/material/Grid';
// Material Kit 2 React components
import MKBox from 'components/box';
// Material Kit 2 React examples
import DefaultInfoCard from 'examples/Cards/InfoCards/DefaultInfoCard';

import CenteredBlogCard from 'examples/Cards/BlogCards/CenteredBlogCard';
// Image
import services_jamaica_4 from '../../../../assets/images/services-page/services_jamaica_4.jpeg';

// eslint-disable-next-line react/prop-types
function ManagedServicesAndStaffAugmentationBand({ disType }) {
  const dataPage = (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Managed Services and Staff Augmentation "
                    description="Managed Services and Staff Augmentation are two approaches organizations can adopt to enhance their capabilities and meet business needs. Managed Services involve outsourcing specific functions to specialized service providers, leveraging their expertise, cost efficiency, scalability, and allowing organizations to focus on core competencies. On the other hand, Staff Augmentation enables organizations to augment their workforce with external resources on a project-based or temporary basis, gaining access to specialized skills, flexibility, seamless integration, and cost-effectiveness. Both approaches provide organizations with the resources and support necessary to optimize operations, address skill gaps, and achieve their strategic objectives."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: 'auto', mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={services_jamaica_4}
              title="Managed Services and Staff Augmentation Service"
              description="Boost your business with our Managed Services and Staff Augmentation solutions. Benefit from expert support, cost efficiency, specialized skills, flexibility, and seamless integration to achieve unparalleled success."
              action={{
                type: 'internal',
                route: '/pages/service/managed-services-and-staff-augmentation',
                color: 'info',
                label: 'find out more',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
  const servicePageInfo = (
    <MKBox component="section" pt={6}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <CenteredBlogCard
              image={services_jamaica_4}
              title="Mng Services & Staff Augmentation"
              description=""
              // Boost your business with our Managed Services and Staff Augmentation solutions. Benefit from expert support, cost efficiency, specialized skills, flexibility, and seamless integration to achieve unparalleled success."
              action={{
                type: 'internal',
                route: '/pages/service/managed-services-and-staff-augmentation',
                color: 'info',
                label: 'find out more',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
  return <div>{disType !== '' && disType === 'band' ? servicePageInfo : dataPage}</div>;
}

export default ManagedServicesAndStaffAugmentationBand;
