/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

//Image
import training_3 from '../../../assets/images/training/training_3.jpeg';
import training_2 from '../../../assets/images/training/training_2.jpeg';
import training_5 from '../../../assets/images/training/training_5.jpeg';
import training_4 from '../../../assets/images/training/training_4.jpeg';

//CSS
import './Training.css';

function WhyUs() {
  return (
    <MKBox component="section" py={5} style={{ background: '#f0f2f5' }}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox pt={5}>
                  <div className="training-benefit">
                    <span>Tailored Training for Business Needs</span>
                  </div>
                  <div className="training-description">
                    <p>
                      We recognize that every organization has unique requirements and challenges.
                      Our training programs are carefully designed to address these specific needs
                      and deliver tangible results. We work closely with your organization to
                      identify the areas where your staff requires training and development. This
                      can range from technical skills in specific technologies, industry-specific
                      knowledge, or even soft skills such as leadership and communication. Our
                      experienced trainers will create customized training modules and deliver
                      engaging sessions that cater to your staff&apos;s learning styles and pace.
                    </p>
                    <p className="training-benefit">
                      By investing in training tailored to your business needs, you can expect:
                    </p>
                  </div>
                  <div className="training-card-container">
                    <div className="training-card">
                      <div className="training-card-content">
                        <img src={training_3} alt="Image 1" className="training-card-image" />
                        <div className="training-card-details">
                          <h3 className="training-card-title">Enhanced Performance</h3>
                          <p className="training-card-description">
                            Training equips your staff with the knowledge and skills necessary to
                            excel in their roles. As they acquire new capabilities and gain a deeper
                            understanding of their work, they become more effective and efficient in
                            their tasks. This leads to improved performance, higher productivity,
                            and better outcomes for your organization.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="training-card">
                      <div className="training-card-content">
                        <img src={training_5} alt="Image 2" className="training-card-image" />
                        <div className="training-card-details">
                          <h3 className="training-card-title">
                            Adaptability to Technological Advancements
                          </h3>
                          <p className="training-card-description">
                            Technology evolves rapidly, and staying up to date is crucial for any
                            business. By providing training on technologies developed by IDMAPS, we
                            ensure that your staff is equipped to leverage the full potential of
                            these tools. This enables your organization to embrace innovation,
                            optimize processes, and stay ahead of the competition.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="training-card">
                      <div className="training-card-content">
                        <img src={training_4} alt="Image 3" className="training-card-image" />
                        <div className="training-card-details">
                          <h3 className="training-card-title">Employee Engagement and Retention</h3>
                          <p className="training-card-description">
                            Investing in training demonstrates your commitment to the growth and
                            development of your staff. This fosters a positive work environment,
                            boosts employee morale, and increases job satisfaction. Employees who
                            feel supported and valued are more likely to stay with the organization,
                            reducing turnover and retaining top talent.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="training-card">
                      <div className="training-card-content">
                        <img src={training_2} alt="Image 4" className="training-card-image" />
                        <div className="training-card-details">
                          <h3 className="training-card-title">Aligned with Business Objective</h3>
                          <p className="training-card-description">
                            By training your staff to meet your specific business needs, you ensure
                            alignment between their skills and your organization&apos;s goals. This
                            enables your workforce to contribute effectively to your strategic
                            objectives and drive overall business success.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WhyUs;
