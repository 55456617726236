/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// Material Kit 2 React examples
import image1 from '../../../assets/images/enterprise-data-management-page/enterprise_data_management_3.jpeg';
import image2 from '../../../assets/images/enterprise-data-management-page/enterprise_data_management_2.jpeg';
import image3 from '../../../assets/images/enterprise-data-management-page/enterprise_data_management_1.jpeg';
import image4 from '../../../assets/images/enterprise-data-management-page/enterprise_data_management_4.jpeg';

// CSS
import '../../Training/sections/Training.css';

function NurturingAssets() {
  return (
    <MKBox component="section" pt={10} style={{ background: '#f0f2f5' }}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span className="training-benefit-list">
                      Enterprise Data Management (EDM): Nurturing Your Data Assets
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      EDM refers to the processes and strategies implemented by organizations to
                      effectively manage and utilize their data assets. It encompasses a wide range
                      of activities, including data collection, storage, integration, quality
                      assurance, and governance. Our EDM solutions are designed to ensure that your
                      data is accurate, reliable, accessible, and secure, while aligning with your
                      organization&apos;s objectives.
                    </p>
                    <p className="training-benefit-list">
                      By partnering with IDMAPS for EDM, you can expect the following
                      training-benefits:
                    </p>
                  </div>
                  <div className="card-container">
                    <div className="card">
                      <div className="card-content">
                        <img src={image1} alt="Image 1" className="card-image" />
                        <div className="card-details">
                          <h3 className="card-title">Data Accuracy and Reliability</h3>
                          <p className="card-description">
                            Our robust data management processes and quality assurance techniques
                            ensure that your data is accurate, consistent, and reliable. This
                            enables you to trust the insights derived from your data and make
                            informed decisions.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-content">
                        <img src={image2} alt="Image 2" className="card-image" />
                        <div className="card-details">
                          <h3 className="card-title">Improved Data Accessibility</h3>
                          <p className="card-description">
                            We focus on ensuring that your data is easily accessible to authorized
                            users when they need it. Through efficient data collection, storage, and
                            integration practices, we enable seamless data access, empowering your
                            teams to derive insights and drive innovation.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-content">
                        <img src={image3} alt="Image 3" className="card-image" />
                        <div className="card-details">
                          <h3 className="card-title">Enhanced Data Security</h3>
                          <p className="card-description">
                            We implement stringent data security measures to protect your sensitive
                            information from unauthorized access, breaches, and data loss. Our data
                            governance practices and compliance frameworks ensure that your data is
                            secure and compliant with regulatory requirements.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-content">
                        <img src={image4} alt="Image 4" className="card-image" />
                        <div className="card-details">
                          <h3 className="card-title">Aligned with Business Objective</h3>
                          <p className="card-description">
                            Our EDM solutions are tailored to align with your organization&apos;s
                            objectives. We work closely with you to understand your business
                            processes, goals, and challenges, and design data management strategies
                            that support your specific needs. This alignment ensures that your data
                            management practices drive value and contribute to your overall business
                            success.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default NurturingAssets;
