/**
 =========================================================
 * Material Kit 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Material Kit 2 React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Navbar.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `name` key is used for the name of the route on the Navbar.
 2. The `icon` key is used for the icon of the route on the Navbar.
 3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 4. The `route` key is used to store the route location which is used for the react router.
 5. The `href` key is used to store the external links location.
 6. The `component` key is used to store the component of its route.
 7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
 8. The `description` key is used to define the description of
 a route under its name.
 9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
 you can set the columns amount based on this key.
 10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
 */

// @mui material components
import Icon from '@mui/material/Icon';

// @mui icons
// import GitHubIcon from "@mui/icons-material/GitHub";
// Pages
import AboutUs from 'layouts/pages/landing-pages/about-us';
import Support from 'layouts/pages/support';
import PortfolioIndustry from 'layouts/pages/portfolioIndustry';
import ContactUs from 'layouts/pages/contact-us';
// import SignIn from 'layouts/pages/authentication/sign-in';
// import SignUp from 'layouts/pages/authentication/sign-up';
import Presentation from 'layouts/pages/presentation';
import DataAnalyticsMorePage from 'layouts/pages/dataAnalyticsMorePage';
import EnterpriseDataManagementMorePage from 'layouts/pages/enterpriseDataManagementMorePage';
import ManagedServicesAndStaffAugmentationMorePage from 'layouts/pages/managedServicesAndStaffAugmentationMorePage';
import TrainingMorePage from 'layouts/pages/trainingMorePage';
import SoftwareDevelopmentMorePage from 'layouts/pages/softwareDevelopmentMorePage';
// temps
import EnhancedLearningCaseStudy from './layouts/pages/portfolioIndustry/EducationCases/enhancedLearningCaseStudy';
import LearningCenterCaseStudy from './layouts/pages/portfolioIndustry/EducationCases/learningCenterCaseStudy';
import SmartSchoolCaseStudy from './layouts/pages/portfolioIndustry/EducationCases/smartSchoolCaseStudy';
import RetailSolutionCaseStudy from './layouts/pages/portfolioIndustry/EcommerceCases/retailSolutionCaseStudy';
import OnlineGroceryCaseStudy from './layouts/pages/portfolioIndustry/EcommerceCases/onlineGroceryCaseStudy';
import BeautySupplyCaseStudy from './layouts/pages/portfolioIndustry/EcommerceCases/beautySupplyCaseStudy';
import LoanServiceCaseStudy from './layouts/pages/portfolioIndustry/FinanceCases/loanServiceCaseStudy';
import FinancialManagementCaseStudy from './layouts/pages/portfolioIndustry/FinanceCases/financialManagementCaseStudy';
import PartnerDrawCaseStudy from './layouts/pages/portfolioIndustry/FinanceCases/partnerDrawCaseStudy';
import AppointmentBookingCaseStudy from './layouts/pages/portfolioIndustry/HealthCareCases/appointmentBookingCaseStudy';
import HealthMonitoringCaseStudy from './layouts/pages/portfolioIndustry/HealthCareCases/healthMonitoringCaseStudy';
import ConnectingPatientsandHealthcareProvidersCaseStudy from './layouts/pages/portfolioIndustry/HealthCareCases/connectingPatientsandHealthcareProvidersCaseStudy';
import TermsOfService from './layouts/pages/termsOfService';
import PrivacyPolicy from './layouts/pages/privacyPolicy';

const routes = [
  {
    type: 'nav',
    name: 'home',
    icon: <Icon>home</Icon>,
    route: '/pages/presentation',
    component: <Presentation />,
  },
  {
    type: 'nav',
    name: 'services',
    icon: <Icon>dashboard</Icon>,
    collapse: [
      {
        type: 'nav',
        name: 'Data Analytics',
        description: 'See all data services',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'data analytics (more)',
            route: '/pages/service/data-analytics-more',
            component: <DataAnalyticsMorePage />,
          },
        ],
      },
      {
        type: 'nav',
        name: 'Enterprise Data Management',
        description: 'See all EDM services',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'Enterprise Data Management (more)',
            route: '/pages/service/enterprise-data-management-more',
            component: <EnterpriseDataManagementMorePage />,
          },
        ],
      },
      {
        type: 'nav',
        name: 'Managed Services and Staff Augmentation',
        description: 'See all MSSA services',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'Managed Services and Staff Augmentation (more)',
            route: '/pages/service/managed-services-and-staff-augmentation',
            component: <ManagedServicesAndStaffAugmentationMorePage />,
          },
        ],
      },
      {
        type: 'nav',
        name: 'Software Development',
        description: 'See all software development services',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'Software Development',
            route: '/pages/service/software-development-more',
            component: <SoftwareDevelopmentMorePage />,
          },
        ],
      },
      {
        type: 'nav',
        name: 'Training',
        description: 'See all training services',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'Training',
            route: '/pages/service/training',
            component: <TrainingMorePage />,
          },
        ],
      },
    ],
  },
  {
    type: 'nav',
    name: 'Portfolio Demo',
    icon: <Icon>dashboard</Icon>,
    collapse: [
      {
        type: 'nav',
        name: 'Education',
        description: '',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'Enhanced Learning',
            route: '/pages/portfolio_industry/education/enhanced_learning_use_case',
            component: <EnhancedLearningCaseStudy />,
          },
          {
            type: 'nav',
            name: 'Learning Center',
            route: '/pages/portfolio_industry/education/learning_center_use_case',
            component: <LearningCenterCaseStudy />,
          },
          {
            type: 'nav',
            name: 'Smart School',
            route: '/pages/portfolio_industry/education/smart_school_use_case',
            component: <SmartSchoolCaseStudy />,
          },
        ],
      },
      {
        type: 'nav',
        name: 'Ecommerce',
        description: '',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'Retail Solution',
            route: '/pages/portfolio_industry/ecommerce/retail_solution_use_case',
            component: <RetailSolutionCaseStudy />,
          },
          {
            type: 'nav',
            name: 'Online Grocery',
            route: '/pages/portfolio_industry/ecommerce/online_grocery_use_case',
            component: <OnlineGroceryCaseStudy />,
          },
          {
            type: 'nav',
            name: 'Beauty Supply',
            route: '/pages/portfolio_industry/ecommerce/Beauty_Supply_use_case',
            component: <BeautySupplyCaseStudy />,
          },
        ],
      },
      {
        type: 'nav',
        name: 'Health Care',
        description: '',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'Appointment Booking',
            route: '/pages/portfolio_industry/health_care/appointment_booking_use_case',
            component: <AppointmentBookingCaseStudy />,
          },
          {
            type: 'nav',
            name: 'Health Monitoring',
            route: '/pages/portfolio_industry/health_care/health_monitoring_use_case',
            component: <HealthMonitoringCaseStudy />,
          },
          {
            type: 'nav',
            name: 'Connecting Patients and Healthcare',
            route:
              '/pages/portfolio_industry/health_care/Connecting_patients_and_health_care_providers_use_case',
            component: <ConnectingPatientsandHealthcareProvidersCaseStudy />,
          },
        ],
      },
      {
        type: 'nav',
        name: 'Finance',
        description: '',
        dropdown: true,
        collapse: [
          {
            type: 'nav',
            name: 'loan service',
            route: '/pages/portfolio_industry/finance/loan_service_use_case',
            component: <LoanServiceCaseStudy />,
          },
          {
            type: 'nav',
            name: 'financial management service',
            route: '/pages/portfolio_industry/finance/financial_management_use_case',
            component: <FinancialManagementCaseStudy />,
          },
          {
            type: 'nav',
            name: 'partner Draw',
            route: '/pages/portfolio_industry/finance/partner_draw_use_case',
            component: <PartnerDrawCaseStudy />,
          },
        ],
      },
    ],
  },
  {
    type: 'nav',
    name: 'Portfolio',
    icon: <Icon>dashboard</Icon>,
    route: '/pages/portfolio',
    component: <PortfolioIndustry />,
  },
  {
    type: 'nav',
    name: 'Support',
    icon: <Icon>dashboard</Icon>,
    route: '/pages/support',
    component: <Support />,
  },
  {
    type: 'nav',
    name: 'Contact Us',
    icon: <Icon>contacts</Icon>,
    route: '/pages/contact-us',
    component: <ContactUs />,
  },
  {
    type: 'nav',
    name: 'About Us',
    icon: <Icon>dashboard</Icon>,
    route: '/pages/landing-pages/about-us',
    component: <AboutUs />,
  },
  // {
  //   type: 'nav',
  //   name: 'Account',
  //   icon: <Icon>dashboard</Icon>,
  //   columns: 1,
  //   rowsPerColumn: 2,
  //   collapse: [
  //     {
  //       type: 'nav',
  //       name: 'account',
  //       collapse: [
  //         {
  //           type: 'nav',
  //           name: 'sign in',
  //           route: '/pages/authentication/sign-in',
  //           component: <SignIn />,
  //         },
  //         {
  //           type: 'nav',
  //           name: 'sign up',
  //           route: '/pages/authentication/sign-up',
  //           component: <SignUp />,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const buttonRoutes = [
  {
    type: 'but',
    name: 'enhanceLearning',
    route: '/pages/portfolio_industry/education/enhanced_learning_use_case',
    component: <EnhancedLearningCaseStudy />,
  },
  {
    type: 'but',
    name: 'learningCentre',
    route: '/pages/portfolio_industry/education/learning_center_use_case',
    component: <LearningCenterCaseStudy />,
  },
  {
    type: 'but',
    name: 'smartSchool',
    route: '/pages/portfolio_industry/education/smart_school_use_case',
    component: <SmartSchoolCaseStudy />,
  },
  {
    type: 'but',
    name: 'retailSolution',
    route: '/pages/portfolio_industry/ecommerce/retail_solution_use_case',
    component: <RetailSolutionCaseStudy />,
  },
  {
    type: 'but',
    name: 'onlineGrocery',
    route: '/pages/portfolio_industry/ecommerce/online_grocery_use_case',
    component: <OnlineGroceryCaseStudy />,
  },
  {
    type: 'but',
    name: 'beautySupply',
    route: '/pages/portfolio_industry/ecommerce/beauty_supply_use_case',
    component: <BeautySupplyCaseStudy />,
  },
  {
    type: 'but',
    name: 'appointmentBooking',
    route: '/pages/portfolio_industry/health_care/appointment_booking_use_case',
    component: <AppointmentBookingCaseStudy />,
  },
  {
    type: 'but',
    name: 'healthMonitoring',
    route: '/pages/portfolio_industry/health_care/health_monitoring_use_case',
    component: <HealthMonitoringCaseStudy />,
  },
  {
    type: 'but',
    name: 'ConnectingPatientsandHealthcareProviders',
    route:
      '/pages/portfolio_industry/health_care/Connecting_patients_and_health_care_providers_use_case',
    component: <ConnectingPatientsandHealthcareProvidersCaseStudy />,
  },
  {
    type: 'but',
    name: 'loanService',
    route: '/pages/portfolio_industry/finance/loan_service_use_case',
    component: <LoanServiceCaseStudy />,
  },
  {
    type: 'but',
    name: 'financialManagementService',
    route: '/pages/portfolio_industry/finance/financial_management_use_case',
    component: <FinancialManagementCaseStudy />,
  },
  {
    type: 'but',
    name: 'partnerDraw',
    route: '/pages/portfolio_industry/finance/partner_draw_use_case',
    component: <PartnerDrawCaseStudy />,
  },
  {
    type: 'but',
    name: 'termsOfService',
    route: '/pages/terms_of_service',
    component: <TermsOfService />,
  },
  {
    type: 'but',
    name: 'privacyPolicy',
    route: '/pages/privacy_policy',
    component: <PrivacyPolicy />,
  },
];

// name: "Managed Services and Staff Augmentation (more)",
//     route: "/pages/service/managed-services-and-staff-augmentation",
export default routes;
