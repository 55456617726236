/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
// Material Kit 2 React components
import MKBox from '../../../components/box';

// CSS
import '../../Training/sections/Training.css';

function AdvantagesAndDisadvantages() {
  return (
    <MKBox component="section" pt={5} pb={5} style={{ background: '#f0f2f5' }}>
      <Container>
        <div className="advantages-and-disadvantages">
          <div className="advantages">
            <h3 className="advantages-disadvantages-title">Advantages of Data Analytics</h3>
            <ul className="advantages-disadvantages-desc">
              <li>
                <i className="fas fa-check data-analytics-icon-small"></i> Informed Decision-Making
              </li>
              <li>
                <i className="fas fa-check data-analytics-icon-small"></i> Improved Efficiency and
                Productivity
              </li>
              <li>
                <i className="fas fa-check data-analytics-icon-small"></i> Enhanced Customer
                Experience
              </li>
              <li>
                <i className="fas fa-check data-analytics-icon-small"></i> Competitive Advantage
              </li>
            </ul>
          </div>

          <div className="disadvantages">
            <h3 className="advantages-disadvantages-title">Disadvantages of Data Analytics</h3>
            <ul className="advantages-disadvantages-desc">
              <li>
                <i className="fas fa-times data-analytics-icon-red-small"></i> Missed Opportunities
              </li>
              <li>
                <i className="fas fa-times data-analytics-icon-red-small"></i> Inaccurate
                Decision-Making
              </li>
              <li>
                <i className="fas fa-times data-analytics-icon-red-small"></i> Lack of Adaptability
              </li>
              <li>
                <i className="fas fa-times data-analytics-icon-red-small"></i> Inefficient Resource
                Allocation
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </MKBox>
  );
}

export default AdvantagesAndDisadvantages;
