/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// Material Kit 2 React examples
import DefaultInfoCardTwo from 'examples/Cards/InfoCards/DefaultInfoCardTwo';

function Policy() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCardTwo
                    // title="Our Mission"
                    description="At IDMAPS, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and disclose information when you use our website and services. By accessing or using our website, you consent to the collection and use of your information as described in this Privacy Policy. Please read this policy carefully before providing any personal information to us."
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCardTwo
                    title="Information We Collect"
                    description="(a) Personal Information: When you interact with our website or use our services, we may collect personal information that you voluntarily provide, such as your name, email address, phone number, and any other information you choose to provide."
                  />
                  <DefaultInfoCardTwo
                    // title="Information We Collect"
                    description="(b) Log Data: Our servers automatically record information that your browser sends whenever you visit our website. This may include your IP address, browser type, the pages you visit, the time spent on those pages, and other statistics."
                  />
                  <DefaultInfoCardTwo
                    // title="Information We Collect"
                    description="(c) Cookies and Similar Technologies: We use cookies and similar technologies to enhance your experience on our website. These technologies collect certain information automatically, such as your browser type, device type, operating system, and browsing preferences. You can manage your cookie preferences through your browser settings."
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCardTwo
                    title="Use of Information"
                    description="We may use the information we collect for the following purposes:"
                  />
                  <DefaultInfoCardTwo description="(a) To provide and maintain our website and services." />
                  <DefaultInfoCardTwo description="(b) To personalize and improve your experience on our website." />
                  <DefaultInfoCardTwo description="(c) To respond to your inquiries, comments, or requests." />
                  <DefaultInfoCardTwo description="(d) To send you newsletters, marketing communications, and other information about our products or services that may be of interest to you. You can opt-out of receiving such communications at any time." />
                  <DefaultInfoCardTwo description="(e) To analyze and gather insights on website usage and trends." />
                  <DefaultInfoCardTwo description="(f) To prevent fraudulent activities and ensure the security of our website." />
                  <DefaultInfoCardTwo description="(g) To comply with legal obligations or enforce our rights." />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCardTwo
                    title="Disclosure of Information"
                    description="We may disclose your information to third parties in the following circumstances:"
                  />
                  <DefaultInfoCardTwo description="(a) Service Providers: We may share your information with trusted third-party service providers who assist us in operating our website, providing services, or conducting business activities on our behalf. These service providers are obligated to keep your information confidential and use it only for the purposes specified by us." />
                  <DefaultInfoCardTwo description="(b) Legal Requirements: We may disclose your information if required by law, regulation, legal process, or governmental request." />
                  <DefaultInfoCardTwo description="(c) Business Transfers: In the event of a merger, acquisition, or any form of corporate reorganization, we may transfer your information to the relevant third party." />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCardTwo
                    title="Data Security"
                    description="We take reasonable measures to protect the security of your information and prevent unauthorized access, loss, misuse, or alteration. However, please note that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security."
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCardTwo
                    title="Links to Third-Party Websites"
                    description="Our website may contain links to third-party websites that are not operated or controlled by IDMAPS. This Privacy Policy does not apply to those third-party websites. We encourage you to review the privacy policies of any third-party websites you visit."
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCardTwo
                    title="Children's Privacy"
                    description="Our website and services are not intended for children under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child without parental consent, we will take steps to remove that information from our servers."
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCardTwo
                    title="Changes to this Privacy Policy"
                    description="We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on our website. We encourage you to review this Privacy Policy periodically to stay informed of any updates."
                  />
                </MKBox>
              </Grid>
              <Grid item>
                <MKBox mb={5}>
                  <DefaultInfoCardTwo
                    title="Contact Us"
                    description="If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at [contact information]."
                  />
                  <DefaultInfoCardTwo description="Thank you for reading our Privacy Policy." />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Policy;
