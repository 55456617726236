/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';
import DefaultInfoCardTwo from '../../../../../examples/Cards/InfoCards/DefaultInfoCardTwo';

//Images
import finance_industry_5 from '../../../../../assets/images/industry/finance_industry_5.jpeg';
import finance_industry_6 from '../../../../../assets/images/industry/finance_industry_6.jpeg';
import portfolio_details_4 from '../../../../../assets/images/software-development/portfolio_details_4.png';
import portfolio_details_3 from '../../../../../assets/images/software-development/portfolio_details_3.png';
import portfolio_details_1 from '../../../../../assets/images/software-development/portfolio_details_1.png';
import portfolio_details_5 from '../../../../../assets/images/software-development/portfolio_details_2.png';

// Css
import './PartnerDrawCaseStudyDetails.css';

function PartnerDrawCaseStudyDetails() {
  return (
    <>
      <MKBox component="section" pt={12} pb={5}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={5} sx={{ ml: '', mt: { xs: 3, lg: 0 } }}>
              <img src={finance_industry_5} className="image-portfolio-details" />
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={12}>
                  <MKBox>
                    <DefaultInfoCardTwo
                      title="Partner Draw"
                      description="This use case delves into an advanced loan service application designed to enhance online services and deliver a seamless loan application and management experience to customers. The focus is on expanding the range of services offered through a state-of-the-art application, ultimately providing a convenient and user-friendly platform for customers to apply for and manage their loans."
                    />
                    <div className="container">
                      <div className="box-portfolio-details">
                        <img src={portfolio_details_4} alt="Country" />
                        <p>
                          <b>Country:</b> Jamaica
                        </p>
                      </div>
                      <div className="box-portfolio-details">
                        <img src={portfolio_details_3} alt="platform" />
                        <p>
                          <b>Platform:</b> iOS , Web
                        </p>
                      </div>
                      <div className="box-portfolio-details">
                        <img src={portfolio_details_1} alt="industry" />
                        <p>
                          <b>Industry:</b> E-Commerce
                        </p>
                      </div>
                      <div className="box-portfolio-details">
                        <img src={portfolio_details_5} alt="duration" />
                        <p>
                          <b>Duration:</b> 2003-Present
                        </p>
                      </div>
                    </div>
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" pb={5} style={{ background: '#f0f2f5' }}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} sx={{ ml: '', mt: { xs: 3, lg: 10 }, mb: { xs: 3, lg: 5 } }}>
              <DefaultInfoCardTwo title="Lorem" description="Lorem ipsom" />
            </Grid>
          </Grid>

          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Team</h2>*/}
                {/*<p className="card-detail">Backend Developer</p>*/}
                {/*<p className="card-detail">Database Administrator</p>*/}
                {/*<p className="card-detail">Frontend Developer</p>*/}
                {/*<p className="card-detail">Quality Assurance Engineer</p>*/}
                {/*<p className="card-detail">Project Manager</p>*/}
                {/*<p className="card-detail">UI/UX Designer</p>*/}
              </div>
            </Grid>
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Tech Stack:</h2>*/}
                {/*<p className="card-detail">React</p>*/}
                {/*<p className="card-detail">Node.js</p>*/}
                {/*<p className="card-detail">Microsoft SQL Server</p>*/}
              </div>
            </Grid>
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Target Audience</h2>*/}
                {/*<p className="card-detail">Businesses</p>*/}
                {/*<p className="card-detail">Clients</p>*/}
              </div>
            </Grid>
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Services</h2>*/}
                {/*<p className="card-detail">Deposit Management</p>*/}
                {/*<p className="card-detail">Partner Draw Calculations</p>*/}
                {/*<p className="card-detail">Account Management</p>*/}
                {/*<p className="card-detail">Notification and Alerts</p>*/}
                {/*<p className="card-detail">Payment Gateway Integration</p>*/}
                {/*<p className="card-detail">User Roles and Permissions</p>*/}
              </div>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" pb={5}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={5} sx={{ ml: '', mt: { xs: 10, lg: 10 } }}>
              <img src={finance_industry_6} className="image-portfolio-details" />
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={12}>
                  <MKBox>
                    <DefaultInfoCardTwo
                      title="Opportunities"
                      description="A small financial company operating as a partner, sought to enhance their service by developing a customized application. The client wanted to provide their clients with a convenient platform to make specific deposits each week and receive their partner draw with accurate percentage calculations. Additionally, the company required efficient account management tools to handle transactions seamlessly and ensure accurate payouts to clients."
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" pb={5} style={{ background: '#f0f2f5' }}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} sx={{ ml: '', mt: { xs: 3, lg: 10 } }}>
              <DefaultInfoCardTwo
                title="Our Solution"
                description="By partnering with us, together we developed a robust application, FinServe, tailored to the needs of small companies operating as partners. Our team conducted comprehensive research and worked closely with the client to understand their unique requirements"
              />
            </Grid>
          </Grid>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} md={12} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <h1 className="portfolio-details-text-header">
                The key outcomes of the project included
              </h1>
              <ul className="portfolio-details-text">
                <li className="card-detail">
                  <span>
                    <b>Streamlined Loan Application Process: </b>
                  </span>
                  The application simplified the deposit process for clients, providing them with a
                  user-friendly platform to make specific deposits each week. The application
                  accurately tracked and recorded these transactions, ensuring transparency and
                  streamlined financial processes.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Accurate Partner Draw Payouts: </b>
                  </span>
                  The automated partner draw calculation feature eliminated manual errors and
                  provided clients with precise and timely payouts. The application&apos;s advanced
                  algorithms ensured accurate calculations based on predefined percentages.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Efficient Account Management: </b>
                  </span>
                  Our client benefited from the user-friendly dashboard, enabling them to manage
                  their accounts efficiently. They could track deposits, monitor partner draw
                  payouts, generate reports, and gain valuable insights into their financial
                  performance.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Improved Client Satisfaction: </b>
                  </span>
                  Clients appreciated the convenience, accuracy, and transparency provided by
                  FinServe. The application&apos;s intuitive interface and timely notifications
                  enhanced their overall experience, leading to increased satisfaction and loyalty.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Time and Cost Savings: </b>
                  </span>
                  The automation of financial transactions, accurate partner draw calculations, and
                  efficient account management tools resulted in significant time and cost savings
                  for their clients. The streamlined processes allowed them to focus on core
                  business activities.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Data Security and Compliance: </b>
                  </span>
                  The application prioritized data security and compliance, implementing stringent
                  measures to protect sensitive financial information. The application adhered to
                  industry best practices and compliance regulations, ensuring data privacy and
                  confidentiality.
                </li>
              </ul>
            </Grid>
          </Grid>

          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} sx={{ ml: '', mt: { xs: 3, lg: 3 } }}>
              <DefaultInfoCardTwo
                title="Conclusion"
                description="The collaboration resulted in the successful development of a customized application for a small company in the finance industry. Leveraging React, Node.js, and Microsoft SQL Server, the application provided a secure and efficient platform for managing financial transactions, accurate partner draw calculations, and comprehensive account management tools. By embracing digital transformation, our client is able to streamline financial transactions, enhance partner draw payouts, and efficiently manage their accounts. The application's intuitive interface, secure payment gateways, and robust features improved client satisfaction, saved time and costs, and ensured data privacy. With this application, our client was able to focus on their core business activities while benefiting from simplified financial processes and accurate partner draw calculations."
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default PartnerDrawCaseStudyDetails;
