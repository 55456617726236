/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';

// Material Kit 2 React examples
import DefaultInfoCardTwo from 'examples/Cards/InfoCards/DefaultInfoCardTwo';

//Image
import training_1 from '../../../assets/images/training/training_1.jpeg';

//CSS
import './Training.css';

function Training() {
  return (
    <MKBox component="section" pt={12} pb={5}>
      <Container>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} lg={5} sx={{ ml: '', mt: { xs: 3, lg: 2 } }}>
            <img className="training-img" src={training_1} alt="training image" />
          </Grid>
          <Grid item xs={12} lg={7}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <DefaultInfoCardTwo
                    title="Training"
                    description="At IDMAPS, we recognize the critical role of a skilled and knowledgeable workforce in
                    achieving business success. That's why we go beyond just offering a range of comprehensive training
                    and certification programs. We take the time to understand your organization's specific needs and
                    tailor our programs accordingly. Whether it's honing your staff's expertise in areas that directly
                    align with your business objectives or equipping them with the necessary skills to effectively
                    utilize the cutting-edge technologies developed by us, we are committed to empowering your team.
                    With our training and certification programs, you can ensure that your workforce is prepared to
                    tackle challenges, adapt to evolving market trends, and drive innovation within your organization."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Training;
