/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';
import DefaultInfoCardTwo from '../../../../../examples/Cards/InfoCards/DefaultInfoCardTwo';

//Images
import education_industry_5 from '../../../../../assets/images/industry/education_industry_5.jpeg';
import education_industry_4 from '../../../../../assets/images/industry/education_industry_4.jpeg';
import portfolio_details_4 from '../../../../../assets/images/software-development/portfolio_details_4.png';
import portfolio_details_3 from '../../../../../assets/images/software-development/portfolio_details_3.png';
import portfolio_details_1 from '../../../../../assets/images/software-development/portfolio_details_1.png';
import portfolio_details_5 from '../../../../../assets/images/software-development/portfolio_details_2.png';

// Css
import './SmartSchoolCaseStudyDetails.css';

function SmartSchoolCaseStudyDetails() {
  return (
    <>
      <MKBox component="section" pt={12} pb={5}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={5} sx={{ ml: '', mt: { xs: 3, lg: 0 } }}>
              <img src={education_industry_5} className="image-portfolio-details" />
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={12}>
                  <MKBox>
                    <DefaultInfoCardTwo
                      title="Education Solutions"
                      description="In this use case, we explore the development of an application revolutionize the education industry by developing an innovative application that would enhance the learning experience for students and streamline administrative processes for educational institutions."
                    />
                    <div className="container">
                      <div className="box-portfolio-details">
                        <img src={portfolio_details_4} alt="Country" />
                        <p>
                          <b>Country:</b> Jamaica
                        </p>
                      </div>
                      <div className="box-portfolio-details">
                        <img src={portfolio_details_3} alt="platform" />
                        <p>
                          <b>Platform:</b> iOS , Web
                        </p>
                      </div>
                      <div className="box-portfolio-details">
                        <img src={portfolio_details_1} alt="industry" />
                        <p>
                          <b>Industry:</b> Education
                        </p>
                      </div>
                      <div className="box-portfolio-details">
                        <img src={portfolio_details_5} alt="duration" />
                        <p>
                          <b>Duration:</b> 2015-Present
                        </p>
                      </div>
                    </div>
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" pb={5} style={{ background: '#f0f2f5' }}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} sx={{ ml: '', mt: { xs: 3, lg: 10 }, mb: { xs: 3, lg: 5 } }}>
              <DefaultInfoCardTwo title="Lorem" description="Lorem ipsom" />
            </Grid>
          </Grid>

          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Team</h2>*/}
                {/*<p className="card-detail">Backend Developer</p>*/}
                {/*<p className="card-detail">Frontend Developer</p>*/}
                {/*<p className="card-detail">Project Manager</p>*/}
                {/*<p className="card-detail">Quality Assurance Engineer</p>*/}
                {/*<p className="card-detail">UI/UX Designer</p>*/}
              </div>
            </Grid>
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Tech Stack:</h2>*/}
                {/*<p className="card-detail">MongoDB</p>*/}
                {/*<p className="card-detail">Node.js</p>*/}
                {/*<p className="card-detail">React</p>*/}
              </div>
            </Grid>
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Target Audience</h2>*/}
                {/*<p className="card-detail">Higher Education Institutions</p>*/}
                {/*<p className="card-detail">Primary Institutions</p>*/}
                {/*<p className="card-detail">Secondary Institutions</p>*/}
                {/*<p className="card-detail">Students</p>*/}
                {/*<p className="card-detail">Teachers</p>*/}
              </div>
            </Grid>
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Services</h2>*/}
                {/*<p className="card-detail">Administrative Tools</p>*/}
                {/*<p className="card-detail">Application Development</p>*/}
                {/*<p className="card-detail">Communication Channels</p>*/}
                {/*<p className="card-detail">Scalability and Customization</p>*/}
              </div>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" pb={5}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={5} sx={{ ml: '', mt: { xs: 10, lg: 10 } }}>
              <img src={education_industry_4} className="image-portfolio-details" />
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={12}>
                  <MKBox>
                    <DefaultInfoCardTwo
                      title="Opportunities"
                      description=" The Institute aimed to revolutionize the learning experience for students by
                    developing a comprehensive educational application. They wanted to create a
                    platform that seamlessly integrates digital resources, interactive content, and
                    personalized learning to enhance student engagement and improve academic
                    performance. The challenge was to build an intuitive and feature-rich
                    application that caters to the diverse needs of students across various
                    educational levels."
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" pb={5} style={{ background: '#f0f2f5' }}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} sx={{ ml: '', mt: { xs: 3, lg: 10 } }}>
              <DefaultInfoCardTwo
                title="Our Solution"
                description="We collaborated with the educational institution to design and develop SmartSchool, a cutting-edge educational application. Our team conducted in-depth discussions with the client to understand their vision, requirements, and desired outcomes. Leveraging our expertise in technology and education, we built a robust platform using modern tools and frameworks such as React and Node.js."
              />
            </Grid>
          </Grid>

          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} md={12} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <h1 className="portfolio-details-text-header">
                The key outcomes of the project included
              </h1>
              <ul className="portfolio-details-text">
                <li className="card-detail">
                  <span>
                    <b>Data-Driven Insights: </b>
                  </span>
                  SmartSchool generated valuable data and analytics on student performance,
                  attendance, and engagement, enabling educators to identify trends, personalize
                  instruction, and make data-driven decisions.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Enhanced Learning Experience: </b>
                  </span>
                  SmartSchool provided students with access to interactive learning modules, virtual
                  classrooms, and personalized learning paths, resulting in increased engagement,
                  improved comprehension, and academic success.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Improved Communication and Collaboration: </b>
                  </span>
                  The application facilitated seamless communication between teachers, students, and
                  parents, enabling effective collaboration and fostering a supportive learning
                  community.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Streamlined Administrative Processes: </b>
                  </span>
                  The administrative tools within SmartSchool simplified tasks such as attendance
                  tracking, grading, and scheduling, saving time and effort for teachers and
                  administrators.
                </li>
              </ul>
            </Grid>
          </Grid>

          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} sx={{ ml: '', mt: { xs: 3, lg: 3 } }}>
              <DefaultInfoCardTwo
                title="Conclusion"
                description="The collaboration resulted in the successful development of SmartSchool, an innovative application that transformed the education landscape. By leveraging advanced technologies and incorporating user-centric design principles, SmartSchool revolutionized the learning experience for students and streamlined administrative processes for educational institutions. The application's interactive features, seamless communication channels, and comprehensive administrative tools have contributed to improved student outcomes, enhanced collaboration, and greater efficiency in the education sector."
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default SmartSchoolCaseStudyDetails;
