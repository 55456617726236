/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';
import DefaultInfoCardTwo from '../../../../../examples/Cards/InfoCards/DefaultInfoCardTwo';

//Images
import education_industry_4 from '../../../../../assets/images/industry/education_industry_4.jpeg';
import education_industry_2 from '../../../../../assets/images/industry/education_industry_2.png';
import portfolio_details_4 from '../../../../../assets/images/software-development/portfolio_details_4.png';
import software_development_3 from '../../../../../assets/images/software-development/portfolio_details_3.png';
import software_development_4 from '../../../../../assets/images/software-development/portfolio_details_1.png';
import software_development_5 from '../../../../../assets/images/software-development/portfolio_details_2.png';

// Css
import './EnhancedLearningCaseStudyDetails.css';

function EnhancedLearningCaseStudyDetails() {
  return (
    <>
      <MKBox component="section" pt={12} pb={5}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={5} sx={{ ml: '', mt: { xs: 3, lg: 0 } }}>
              <img
                src={education_industry_2}
                className="image-portfolio-details"
                alt="education image"
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={12}>
                  <MKBox>
                    <DefaultInfoCardTwo
                      title="Education Institute"
                      description="In this use case, we explore the development of a cutting-edge mobile application. This application provideds students with an innovative and engaging learning platform, empowering them to learn at their own pace and explore subjects in a personalized manner."
                    />
                    <div className="container">
                      <div className="box-portfolio-details">
                        <img src={portfolio_details_4} alt="Country" />
                        <p>
                          <b>Country:</b> Jamaica
                        </p>
                      </div>
                      <div className="box-portfolio-details">
                        <img src={software_development_3} alt="Android" />
                        <p>
                          <b>Platform:</b> iOS , Web
                        </p>
                      </div>
                      <div className="box-portfolio-details">
                        <img src={software_development_4} alt="Windows" />
                        <p>
                          <b>Industry:</b> Education
                        </p>
                      </div>
                      <div className="box-portfolio-details">
                        <img src={software_development_5} alt="React" />
                        <p>
                          <b>Duration:</b> 2003-Present
                        </p>
                      </div>
                    </div>
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" style={{ background: '#f0f2f5' }}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} sx={{ ml: '', mt: { xs: 3, lg: 10 }, mb: { xs: 3, lg: 5 } }}>
              <DefaultInfoCardTwo title="Lorem" description="Lorem ipsom" />
            </Grid>
          </Grid>

          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Team</h2>*/}
                {/*<p className="card-detail">Project Manager</p>*/}
                {/*<p className="card-detail">UI/UX Designer</p>*/}
                {/*<p className="card-detail">Frontend Developer</p>*/}
                {/*<p className="card-detail">Backend Developer</p>*/}
                {/*<p className="card-detail">Quality Assurance Engineer</p>*/}
              </div>
            </Grid>
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Tech Stack:</h2>*/}
                {/*<p className="card-detail">React</p>*/}
                {/*<p className="card-detail">Node.js</p>*/}
              </div>
            </Grid>
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Target Audience</h2>*/}
                {/*<p className="card-detail">K-12</p>*/}
                {/*<p className="card-detail">College</p>*/}
                {/*<p className="card-detail">High School</p>*/}
              </div>
            </Grid>
            <Grid item xs={12} lg={3} md={6} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <div className="card-portfolio-details">
                {/*<h2 className="card-portfolio-details-header">Services</h2>*/}
                {/*<p className="card-detail">Application Development</p>*/}
                {/*<p className="card-detail">Quality Assurance</p>*/}
                {/*<p className="card-detail">UX/UI Design</p>*/}
              </div>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" pb={5}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={5} sx={{ ml: '', mt: { xs: 10, lg: 10 } }}>
              <img
                src={education_industry_4}
                className="image-portfolio-details"
                alt="education image"
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={12}>
                  <MKBox>
                    <DefaultInfoCardTwo
                      title="Opportunities"
                      description=" The Institute aimed to revolutionize the learning experience for students by
                    developing a comprehensive educational application. They wanted to create a
                    platform that seamlessly integrates digital resources, interactive content, and
                    personalized learning to enhance student engagement and improve academic
                    performance. The challenge was to build an intuitive and feature-rich
                    application that caters to the diverse needs of students across various
                    educational levels."
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" pb={5} style={{ background: '#f0f2f5' }}>
        <Container>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} sx={{ ml: '', mt: { xs: 3, lg: 10 } }}>
              <DefaultInfoCardTwo
                title="Our Solution"
                description="By partnering with us, together we developed a cutting-edge educational application. Our team of experts worked closely with the client to understand their requirements and design a solution tailored to their needs. The application was built using a robust tech stack, including React and Node.js, to ensure a scalable and efficient platform."
              />
            </Grid>
          </Grid>
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} md={12} sx={{ ml: '', mb: { xs: 0, lg: 3 } }}>
              <h1 className="portfolio-details-text-header">
                The key outcomes of the project included
              </h1>
              <ul className="portfolio-details-text">
                <li className="card-detail">
                  <span>
                    <b>Increased student engagement: </b>
                  </span>
                  The interactive and personalized learning features of the application resulted in
                  improved student engagement and motivation.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Enhanced academic performance: </b>
                  </span>
                  Students benefited from access to a wide range of educational resources,
                  interactive lessons, and assessment tools, leading to improved academic
                  performance.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Streamlined content delivery: </b>
                  </span>
                  The application provided a centralized platform for teachers to deliver digital
                  content, assignments, and assessments, simplifying the teaching process.
                </li>
                <li className="card-detail">
                  <span>
                    <b>Scalable and future-ready: </b>
                  </span>
                  The application was built using modern technologies, ensuring scalability and
                  adaptability to future educational needs.
                </li>
              </ul>
            </Grid>
          </Grid>

          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} lg={12} sx={{ ml: '', mt: { xs: 3, lg: 3 } }}>
              <DefaultInfoCardTwo
                title="Conclusion"
                description="This resulted in the successful development of an innovative educational application. By leveraging our expertise in application development, UX/UI design, and quality assurance, we delivered a solution that transformed the learning experience for students. The application's intuitive interface, comprehensive content, and interactive features proved instrumental in enhancing student engagement, improving academic performance, and revolutionizing the education industry."
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default EnhancedLearningCaseStudyDetails;
