/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from '../../../components/box';

//CSS
import '../../Training/sections/Training.css';

function WhyUs() {
  return (
    <MKBox component="section" style={{ background: '#f0f2f5' }}>
      <Container>
        <Grid container spacing={2} pt={10} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span>Why Choose Us?</span>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span className="training-benefit-list">
                      <i className="fas fa-lightbulb icon"></i> Gain Deeper Insights
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      With our data analytics services, you gain a deeper understanding of your
                      business operations, customer behavior, market trends, and competitive
                      landscape. We uncover hidden patterns and correlations within your data,
                      enabling you to make data-driven decisions that propel your business forward.
                    </p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span className="training-benefit-list">
                      <i className="fas fa-bullseye icon"></i>Quality Data
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      Our data analytics process begins with meticulous data collection and
                      cleansing to ensure accuracy and reliability. Through interactive dashboards
                      and reports, we provide clear and concise representations of your data, making
                      it easier to identify trends, spot opportunities, and address challenges.
                    </p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center" style={{ marginTop: '30px' }}>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span className="training-benefit-list">
                      <i className="fas fa-unlock-alt icon"></i> Unlock Your Business Potential
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      Partnering with us for data analytics unlocks improved operational efficiency,
                      enhanced customer experiences, better risk management, and increased
                      profitability. Our focus is on delivering actionable insights that drive
                      measurable results for your business.
                    </p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox>
                  <div className="training-benefit">
                    <span className="training-benefit-list">
                      <i className="fas fa-puzzle-piece icon"></i> Tailored Solutions for Your Needs
                    </span>
                  </div>
                  <div className="training-description">
                    <p>
                      Whether you require descriptive analytics to understand past trends,
                      diagnostic analytics to uncover reasons behind outcomes, predictive analytics
                      to forecast future events, or prescriptive analytics to optimize
                      decision-making, our team delivers tailored solutions to meet your specific
                      needs.
                    </p>
                  </div>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WhyUs;
