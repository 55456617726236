/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/box';
import MKTypography from '../../../components/typography';

//Image
import client_1 from '../../../assets/images/software-development/clients_1.png';
import client_2 from '../../../assets/images/software-development/clients_2.png';
import client_3 from '../../../assets/images/software-development/clients_3.png';
import client_4 from '../../../assets/images/software-development/clients_4.png';
import client_5 from '../../../assets/images/software-development/clients_5.jpg';
import client_6 from '../../../assets/images/software-development/clients_6.png';
import client_7 from '../../../assets/images/software-development/clients_7.png';
import client_8 from '../../../assets/images/software-development/clients_8.png';
import bgFront from '../../../assets/images/rotating-card-bg-front.jpeg';
import bgBack from '../../../assets/images/rotating-card-bg-back.jpeg';

import RotatingCard from '../../../examples/Cards/RotatingCard';
import RotatingCardFront from '../../../examples/Cards/RotatingCard/RotatingCardFront';
import RotatingCardBack from '../../../examples/Cards/RotatingCard/RotatingCardBack';
function Clients() {
  return (
    <MKBox component="section" pt={5} pb={4}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ mx: 'auto', textAlign: 'center', pt: '100px', pb: '50px' }}
        >
          <MKTypography
            variant="h1"
            color="#344667"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down('md')]: {
                fontSize: size['3xl'],
              },
            })}
          >
            Some Of Our Clients
          </MKTypography>
          <MKTypography variant="body1" color="#344667" opacity={0.8} mt={1} mb={1}>
            We continue to serve a diverse range of clients, including industry leaders and renowned
            organizations.
          </MKTypography>
        </Grid>
        <Grid container item xs={12} spacing={3} alignItems="center">
          <Grid item xs={6} lg={3} md={3}>
            <RotatingCard>
              <RotatingCardFront image={bgFront} partnerImage={client_1} color="dark" />
              <RotatingCardBack
                image={bgBack}
                description="Financial gamechangers here to revolutionize wealth accessibility with smarter tech, teams and interactions."
                action={{
                  type: 'internal',
                  route: 'https://www.barita.com/',
                  label: 'Learn More',
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            <RotatingCard>
              <RotatingCardFront image={bgFront} partnerImage={client_2} color="dark" />
              <RotatingCardBack
                image={bgBack}
                description="Investing in high growth and undervalued companies, mergers and acquisitions."
                action={{
                  type: 'internal',
                  route: 'https://cornerstone.com.jm/',
                  label: 'Learn More',
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            <RotatingCard>
              <RotatingCardFront image={bgFront} partnerImage={client_3} color="dark" />
              <RotatingCardBack
                image={bgBack}
                description="Lynk makes sending, receiving and requesting money a breeze."
                action={{
                  type: 'internal',
                  route: 'https://www.lynk.us/',
                  label: 'Learn More',
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            <RotatingCard>
              <RotatingCardFront image={bgFront} partnerImage={client_4} color="dark" />
              <RotatingCardBack
                image={bgBack}
                description="NCB prioritizes profitability while offering highly competitive and innovative products and services."
                action={{
                  type: 'internal',
                  route: 'https://www.jncb.com/',
                  label: 'Learn More',
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            <RotatingCard>
              <RotatingCardFront image={bgFront} partnerImage={client_5} color="dark" />
              <RotatingCardBack
                image={bgBack}
                description="UWIH offers a wide range of health care services."
                action={{
                  type: 'internal',
                  route: 'https://uhwi.gov.jm/',
                  label: 'Learn More',
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            <RotatingCard>
              <RotatingCardFront image={bgFront} partnerImage={client_6} color="dark" />
              <RotatingCardBack
                image={bgBack}
                description="Digicel is a mobile phone network and home entertainment provider."
                action={{
                  type: 'internal',
                  route: 'https://www.digicelgroup.com/jm/en.html',
                  label: 'Learn More',
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            <RotatingCard>
              <RotatingCardFront image={bgFront} partnerImage={client_7} color="dark" />
              <RotatingCardBack
                image={bgBack}
                description="JMMB Jamaica is an integrated financial services provider."
                action={{
                  type: 'internal',
                  route: 'https://jm.jmmb.com/',
                  label: 'Learn More',
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            <RotatingCard>
              <RotatingCardFront image={bgFront} partnerImage={client_8} color="dark" />
              <RotatingCardBack
                image={bgBack}
                description="VMBS ensures access to the financial products, services and education needed."
                action={{
                  type: 'internal',
                  route: 'https://vmbuildingsociety.myvmgroup.com/',
                  label: 'Learn More',
                }}
              />
            </RotatingCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Clients;
